import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://046e9375465ed6a5677c1a188014b8a1@sentry.k8s.vgnett.no/115",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.05,
  sampleRate: 0.05,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});